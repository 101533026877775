.LoadingModal {
  &__spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom;
    border: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn);
  }
}
