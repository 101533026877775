.Nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-nav;
  display: block !important;
  width: 100vw;
  padding: 0;

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0.5rem 1rem 0.05rem 0;
    margin-top: 2.5rem;
    margin-left: 1.625rem;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
    padding: 0;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgb(76 84 92 / 50%);

    @include media-breakpoint-down(sm) {
      padding: 0 1rem 0 0;
    }

    @include media-breakpoint-only(md) {
      padding: 0 2rem;
    }

    @include media-breakpoint-up(lg) {
      box-shadow: none;
    }
  }

  &__link-wrap {
    position: relative;
  }

  &__links {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 300px;

    @include media-breakpoint-down(md) {
      padding: 1rem 2rem;
    }

    @include media-breakpoint-only(md) {
      width: 383px;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      display: block;
      width: 100%;
      max-width: 16rem;
    }

    &--open {
      display: block;
      background-color: $white;

      @include media-breakpoint-down(md) {
        box-shadow: 0 1px 2px 1px $gray-200;
      }
    }
  }

  &__header {
    padding: 1rem;
    margin-bottom: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      width: 15.5rem;
      padding: 1.25rem 0 0.75rem;
    }
  }

  &__section {
    display: flex;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    margin: 0 0.75rem;
    font-size: 1.5rem;
  }

  &__close-icon {
    span {
      display: block;
      width: 30px;
      height: 0.175rem;
      margin: 0.33rem 0;
      background-color: $gray-500;
      background-image: none !important;
      transition: all 0.2s ease-in-out;
    }

    &--open {
      span:nth-child(1) {
        transform: translateY(0.5rem) rotate(-135deg);
      }

      span:nth-child(2) {
        transform: scale(0);
      }

      span:nth-child(3) {
        transform: translateY(-0.5rem) rotate(135deg);
      }
    }
  }

  &--fixed {
    @include media-breakpoint-down(md) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  ul {
    padding: 0;
    list-style: none;
  }
}
