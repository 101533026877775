// ==========================================================================
// Trumps
// ==========================================================================
// Helpers, overrides, utilities, only affect one piece of the DOM at a time.
// ==========================================================================

// Bootstrap Overrides
.navbar-toggler {
  padding: 0.25rem 0;
  border: 0;

  &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.navbar-toggler-icon {
  background-image: none !important;
}

@include media-breakpoint-up(lg) {
  .navbar-expand-lg .navbar-collapse {
    display: block !important;
  }
}