.ContentBlock {
  padding-bottom: 1rem;

  &__primary {
    padding-top: 1rem;
    font-size: 1.5rem;
    line-height: 1.25rem;
  }

  &__secondary {
    padding-bottom: 1rem;
  }

  &__third-level {
    padding-bottom: 1rem;
    font-size: 1rem;
  }

  .do-not-sell {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    font-weight: $font-weight-bold;
    color: $gray-500;
    background-color: $gray-300;
    border: 1px solid $gray-500;
    border-radius: 0.25rem;

    @include media-breakpoint-down(sm) {
      margin-top: -0.25rem;
    }

    span {
      display: inline-block;
    }

    i {
      padding: 0.25rem;
      font-size: 2rem;
    }

    &:hover,
    &:active,
    &:focus {
      color: $white;
      text-decoration: none;
      background-color: $gray-500;
    }
  }

  ul ul li {
    padding-bottom: 1rem;
  }

  table {
    margin-bottom: 1rem;
    font-size: 0.419rem;
    border-collapse: collapse;
    border: 1px solid $gray-500;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  th,
  td {
    padding: 1rem;
    vertical-align: top;
    border: 1px solid $gray-500;
  }

  tr td:first-of-type {
    font-size: 0.525rem;
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }

  tr td:nth-of-type(3) {
    text-align: center;
    vertical-align: middle;
  }
}
