.ISHeader {
  width: 100%;
  padding: 0.75rem 0;
  box-shadow: 0 2px 4px 0 rgb(76 84 92 / 50%);

  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-header;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
  }

  &__IS-logo {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75rem;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }

  &__link {
    &:focus,
    &:hover {
      outline: none;
    }
  }

  &__logos {
    display: flex;
    gap: 1.875rem;
    align-items: center;
    justify-content: center;
  }
}
