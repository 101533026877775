.extraCredit {
  .ContentBlock {
    &__primary {
      color: #1d4bb6;
    }

    a,
    a:visited {
      color: #1d4bb6;
    }
  }
}
