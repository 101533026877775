.FooterDisclaimer {
  width: 100%;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  font-size: 0.8rem;
  line-height: 1.48;
  border-top: 1px solid $gray-350;

  a,
  a:hover,
  a:focus,
  a:active {
    color: $white;
    text-decoration: underline;
  }
}
