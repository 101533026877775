// ==========================================================================
// Base
// ==========================================================================
// Unclassed HTML elements (type selectors), h1-h6, links, etc.
// ==========================================================================

@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap';

body {
  font-family: $font-family;
  font-size: 16px;
  font-weight: $font-weight-normal;
  color: $gray-500;
  background-color: $white;
}

a,
a:visited {
  color: $blue;
  text-decoration: none;
}

a:focus,
a:hover {
  color: $blue-dark;
  text-decoration: underline;
}

h1 {
  font-size: 1.5rem;
  font-weight: $font-weight-bold;
  line-height: 1.3;
}

h2 {
  font-size: 1.25rem;
  font-weight: $font-weight-bold;
}

h3 {
  font-size: 1rem;
  font-weight: $font-weight-bold;
  line-height: 1.875rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0 1000px $white inset;
}
