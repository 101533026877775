.ValidatedSelect {
  &__control {
    width: 100%;
    color: $gray-900;
    background-color: $white;
    border-color: $gray-200 !important;
    box-shadow: none !important;

    &--menu-is-open {
      border-color: $gray-500 !important;
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      outline: 0;

      .ValidatedSelect__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__value-container {
    padding: 0.5rem 0.75rem !important;
  }

  &__indicator-separator {
    display: none;
  }

  &__indicators {
    padding: 0 0.5rem;
    color: $black;
    background-color: $gray-200;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
      color: $black;
    }
  }

  &__menu {
    margin: 0 !important;
    border: 1px solid $black;
    border-top: 0;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: none !important;
  }

  &__menu-list {
    width: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0;
  }

  &__option {
    padding: 0.75rem 1rem !important;

    &--is-focused,
    &:active,
    &:focus {
      background-color: $white !important;
    }

    &--is-selected,
    &:hover {
      color: $gray-500 !important;
      background-color: $gray-100 !important;
    }

    &:nth-of-type(2) {
      border-top: 1px solid $gray-500;
      border-bottom: 1px solid $gray-500;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
