@import 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap';

.GFCFooter {
  padding: 3rem 0;
  font-family: Poppins, sans-serif;
  font-size: 0.8rem;
  color: #333;
  box-shadow: inset 0 0.25rem 0.25rem #0000001a;

  .container {
    max-width: unset;

    @include media-breakpoint-up(md) {
      max-width: 48rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 75rem;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  .footer-logo {
    display: block;
    width: 10.3125rem;
    margin: 0 auto 2rem;

    @include media-breakpoint-up(md) {
      margin: 0 0 1.25rem;
      margin-bottom: 1.25rem;
    }

    @include media-breakpoint-up(lg) {
      display: inline-block;
      margin-bottom: 1rem;
    }
  }

  .footer-link-group {
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    text-align: center;
    letter-spacing: 0.0156rem;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
      text-align: left;
    }
  }

  .footer-link-group-anchor {
    display: inline-block;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    color: #333;
    text-decoration: none;
  }

  .footer-copy {
    font-size: 0.75rem;
    letter-spacing: 0.0156rem;

    a {
      color: inherit;
      text-decoration: underline;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .footer-other-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    list-style-type: none;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
    }
  }

  .footer-other-links-anchor {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
    color: #333;
    text-decoration: none;
    border-right: 1px solid;
  }

  .footer-other-links li:first-of-type a {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  .footer-other-links li:last-of-type a {
    @include media-breakpoint-up(lg) {
      border-right: none;
    }
  }

  .footer-separator {
    height: 0.0625rem;
    margin: 1.5rem 0;
    background-color: #ff7c17;
    border: none;
  }

  .footer-link-group-heading {
    display: block;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.79;
    color: #34609d;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.0156rem;

    @include media-breakpoint-up(lg) {
      line-height: 1.79;
      text-align: left;
    }
  }
}

.ManageMyDataForm__title--GFC {
  color: #1d4bb6;
}

.ManageMyDataPage .GFC-privacy-tos-link {
  color: #1d4bb6;
  cursor: pointer;
}

.ManageMyDataPage .GFC-privacy-tos-link:hover,
.ManageMyDataPage .GFC-privacy-tos-link:active,
.ManageMyDataPage .GFC-privacy-tos-link:focus {
  color: #1d4bb6;
  text-decoration: underline;
  cursor: pointer;
}
