.GBRFooter {
  padding-top: 1.5rem;
  padding-bottom: 3rem;
  font-weight: 400;
  color: #555;
  letter-spacing: 0.25px;
  background: #fff;

  & a {
    color: currentcolor;
    text-decoration: none;

    &:hover,
    &:focus {
      color: currentcolor;
      text-decoration: none;
    }
  }

  .GBRFooterContainer {
    @include media-breakpoint-up(lg) {
      max-width: 71.25rem;
    }
  }

  .domainLogo {
    height: auto;
    margin-bottom: 1rem;
  }

  .footerLinks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem 2rem;
    font-size: 0.75rem;

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0 2rem;
      font-size: 0.875rem;
    }
  }

  .linkGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .linkGroupHeader {
    font-weight: 700;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0.5rem;
    }
  }

  .separator {
    display: block;
    width: 100%;
    /* stylelint-disable-next-line unit-disallowed-list */
    height: 1px;
    margin: 1.5rem 0;
    line-height: 0;
    text-align: center;
    background-color: currentcolor;
  }

  .footerCopy {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.75rem;

    & > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    /* INFO: The specificity is correct */
    /* stylelint-disable-next-line no-descending-specificity */
    & a {
      text-decoration: underline;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
