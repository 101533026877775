// ==========================================================================
// Generic
// ==========================================================================
// Ground-zero styles, low-specificity, far-reaching.
// ==========================================================================

@import-normalize;
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
