.ManageMyDataForm {
  max-width: $max-container-width;

  &__field-wrap {
    position: relative;
    padding-top: 1rem;
  }

  &__field {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 1.5rem 1rem;
    color: $gray-700;
    border: 1px solid $gray-200;
    border-radius: 0.25rem;

    &::placeholder {
      color: $gray-900;
    }

    &:hover,
    &:focus {
      color: $gray-700;
      outline-style: none;
      box-shadow: none;
    }

    &:focus {
      border: 1px solid $blue;
    }
  }

  &__dropdown {
    width: 100%;
  }

  &__icon {
    position: absolute;
    top: 3.75rem;
    right: 2rem;
    display: none;
    font-size: 1.5rem;
    color: $red;
  }

  &__error {
    &-text {
      display: block;
      margin-top: 0.25rem;
      font-size: 0.8rem;
      line-height: 1.56;
      color: $red;
    }

    label {
      color: $red;
    }

    input {
      border-color: $red;
    }

    .ValidatedSelect__control {
      border-color: $red !important;
    }

    .ManageMyDataForm__icon {
      display: block;
    }
  }

  &__recaptcha {
    margin-top: 1.875rem;
  }

  &__cta {
    width: 100%;
    padding: 1.125rem 1.5rem;
    margin: 1.75em 0;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    color: $white;
    background-color: $orange;
    border: 0;
    border-radius: 0.444rem;

    @include media-breakpoint-up(md) {
      max-width: 290px;
    }

    &:disabled {
      background-color: $orange-light;
      border-color: $orange-light;
    }

    &:hover {
      background-color: $orange-light;
    }
    
    &:active,
    &:focus,
    &:active:hover {
      color: $white;
      background-color: $orange;
      border-color: $orange;
      outline: 1px solid $orange;
      box-shadow: none;
    }
  }
}
