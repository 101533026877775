// ==========================================================================
// Commons
// ==========================================================================
// Reusable and generic styles that may be part of a component.
// ==========================================================================

.Page {
  max-width: $max-container-width;
  padding-top: 5rem;
  padding-bottom: 3.125em;

  @include media-breakpoint-up(md) {
    padding-top: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 4.438rem;
  }

  &__image {
    padding: 1.875em 0;

    @include media-breakpoint-up(lg) {
      padding: 3.75rem 0 2.5rem;
    }
  }

  &__header {
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__content {
    display: block;
    max-width: 440px;
    margin: 1.25rem auto 0;
    line-height: 1.5;
    text-align: left;

    @include media-breakpoint-up(md) {
      max-width: 537px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 555px;
    }
  }

  &__cta {
    display: inline-block;
    padding: 1.125rem 1.5rem;
    margin: 1.875em 0;
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    color: $white;
    background-color: $green;
    border: 1px solid transparent;
    border-radius: 0.444rem;

    &:hover,
    &:focus {
      color: $white;
      background-color: $green-dark;
    }
  }
}
