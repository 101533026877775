.GFCHeader {
  width: 100%;
  height: 80px;
  padding-top: 1rem;
  box-shadow: 0 2px 4px 0 rgb(76 84 92 / 50%);

  @include media-breakpoint-down(md) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z-index-header;
  }

  &__wrap {
    max-width: 1140px;
    margin: 0 auto;
  }

  &__link {
    &:focus,
    &:hover {
      outline: none;
    }
  }
}
