.NavLink {
  font-size: 1rem;
  line-height: 1.875rem;

  &__primary {
    padding: 0.5rem 0;
    font-weight: $font-weight-bold;
    border-top: 1px solid $gray-200;

    &:first-of-type {
      @include media-breakpoint-down(md) {
        border-top: 0;
      }
    }
  }

  &__third {
    display: flex;
    margin-left: 0.5rem;
    font-weight: $font-weight-light;

    &::before {
      margin-right: 0.5rem;
      content: '-';
    }
  }

  a,
  a:visited,
  a:focus,
  a:hover {
    color: $gray-500;
    text-decoration: none;
  }

  .fa {
    margin-right: 0.5rem;
    font-weight: $font-weight-normal;
  }
}
