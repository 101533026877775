.ErrorImg {
  &__image {
    max-width: 290px;
    margin: 0;

    @include media-breakpoint-up(md) {
      max-width: 400px;
    }

    @include media-breakpoint-up(lg) {
      max-width: 543px;
    }
  }
}
