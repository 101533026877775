.Content {
  margin: 3.75rem 0 3rem;

  @include media-breakpoint-only(md) {
    margin: 4.5rem 0.5rem 3rem;
  }

  @include media-breakpoint-up(md) {
    padding: 1.25rem;
    border: 1px solid $gray-400;
    border-radius: 0.25rem;
  }

  @include media-breakpoint-up(lg) {
    margin: 2.5rem 0 3rem;
  }
}
